import React from "react";
import "@reach/dialog/styles.css";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import ModalWrapper from "./ModalWrapper";
import { ModalIcon } from "../icon/Icon";

const modalStyle = {
  modal: {
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    border: "none",
    strokeWidth: "0.341px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    margin: 0,
  },
};

const ChildrenWrapper = styled.div`
  gap: 40px;
  position: absolute;
  width: 90%;
  height: auto;
  background: #161616;
  border-radius: 30px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04),
    0px 24px 32px 0px rgba(0, 0, 0, 0.04);
  padding: 1rem;
  max-width: 560px !important;

  @media (min-width: 1025px) {
    padding: 2rem;
  }
`;

type modalPropsTypes = {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  closeOnOverlayClick?: boolean;
  showCloseIcon?: boolean;
  closeModal?: () => void;
  openModal?: () => void;
};

export const SmallModal = ({
  open,
  setOpen,
  children,
  closeOnOverlayClick,
  showCloseIcon,
  closeModal,
}: modalPropsTypes) => {
  const handleCloseModal = () => {
    if (setOpen) {
      return setOpen(false);
    } else if (closeModal) {
      return closeModal();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      showCloseIcon={showCloseIcon}
      closeIcon={<ModalIcon closeModal={closeModal} />}
      styles={modalStyle as any}
      closeOnOverlayClick={closeOnOverlayClick}
      center={true}
      focusTrapped={true}
    >
      <SmallModalChildrenWrapper>
        <ModalWrapper onClose={handleCloseModal}>{children}</ModalWrapper>
      </SmallModalChildrenWrapper>
    </Modal>
  );
};

const SmallModalChildrenWrapper = styled(ChildrenWrapper)`
  max-height: 485px !important;
  max-width: 450px !important;
`;

export const MediumModal = ({
  open,
  setOpen,
  children,
  closeOnOverlayClick,
  showCloseIcon,
  closeModal,
}: modalPropsTypes) => {
  const handleCloseModal = () => {
    if (setOpen) {
      return setOpen(false);
    } else if (closeModal) {
      return closeModal();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      showCloseIcon={showCloseIcon}
      closeIcon={<ModalIcon closeModal={closeModal} />}
      styles={modalStyle as any}
      closeOnOverlayClick={closeOnOverlayClick}
      center={true}
      focusTrapped={true}
    >
      <MediumModalChildrenWrapper>
        <ModalWrapper onClose={handleCloseModal}>{children}</ModalWrapper>
      </MediumModalChildrenWrapper>
    </Modal>
  );
};

const MediumModalChildrenWrapper = styled(ChildrenWrapper)`
  max-height: 734px !important;
`;
