type ILinksData = {
    linkTitle: string;
    linkSubtitle: string;
    id: number;
    link: string;
  }[];
  
  export const linksData: ILinksData = [
    {
      linkTitle: "Simplify HR for your Business",
      linkSubtitle: "Get the Help You Need",
      id: 0,  
      link: "/",
    },
    {
      linkTitle: "Elevate Your Hr Tech Game",
      linkSubtitle: "Join Our HR Tech Workshop",
      id: 1,
      link: "/",
    },
    {
      linkTitle: "Empower Your Business",
      linkSubtitle: "Let's Transform the Way You Operate",
      id: 2,
      link: "/",
    },
    {
      linkTitle: "Connect with the Experts",
      linkSubtitle: "Join Our Community of HR Tech professionals",
      id: 3,
      link: "/",
    },
   
    {
      linkTitle: "Workforce Readiness",
      linkSubtitle: "Connecting Talents to Opportunities",
      id: 4,
      link: "/",
    },
    {
      linkTitle: "Brand in a box",
      linkSubtitle: "Unleash your Brand's Potential",
      id: 5,
      link: "/",
    },
  ];