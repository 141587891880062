import {
  CSSProperties,
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactNode,
  InputHTMLAttributes,
  Key,
} from "react";
export interface IFormInput extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  name: string;
  type: HTMLInputTypeAttribute;
  cName?: string;
  id: string;
  hasLabel: boolean;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  value?: any;
  rest: any;
  errorText: string | undefined;
  icon?: ReactNode;
  control: any;
}

export type ISelectData = {
  label: string;
  value: string | number;
}[];

export interface IRadioData extends ISelectData {}

export interface IRadioComponent extends InputHTMLAttributes<HTMLInputElement> {
  options: IRadioData;
  name: string;
  handleChange: (value: any) => void;
  selectedValue: string;
  errorText: any;
  rest: any;
  label: string;
  hasLabel: boolean;
  control: any;
}

export const enum ButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  TERTIARY = "TERTIARY",
  NEUTRAL = "NEUTRAL",
}

export type IButton = {
  buttonType: ButtonType; // can either be PRIMARY or SECONDARY or TERTIARY
  text: string; // text of the button
  hasLine: boolean; // line for the button
  style: {}; // additional stylings for button
  click: (() => void) | undefined; // function to call
  isLoading: boolean; // if true, disable button to prevent further clicking
  cName?: string; // additional className for the button
  isAnimatable: boolean; // describe if button should animated.
};

export type IButtonLine = {
  lineBackgroundColor: string;
};

export type IHoveredCard = {
  actionable: string;
  description: string;
  id: number;
  title: string;
  link?: string;
};

export type IBankDetails = {
  closeModal: () => void;
  accountNumber: string;
  bankName: string;
  bankAccountName: string;
  swiftCode: string;
  hasSwiftCode: boolean;
  hasRouting: boolean;
  routing: string;
};
