import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import InputComponent from "../../../utils/input/Input";
import { useForm } from "react-hook-form";
import RadioComponent from "../../../utils/radio/RadioComponent";
import { Schema } from "../../../validators/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useExport } from "../../../hooks/useExport";
import ButtonComponent from "../../../utils/button/ButtonComponent";
import { ButtonType } from "../../../types/general.types";
import { registerRsvp } from "../../../services/rsvpServices/rsvpServices";
import { toast } from "react-toastify";
import {
  attendTheParty,
  modeOfAttendance,
} from "../../../assets/data/rsvp-form-data/rsvpData";
import CheckboxComponent from "../../../utils/checkbox/CheckboxComponent";

type Props = {
  closeModal: () => void;
};

const RsvpForm = ({ closeModal }: Props) => {
  const [attendParty, setAttendParty] = useState<string>("yes");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAttendanceMode, setSelectedAttendanceMode] = useState<
    string | number | undefined
  >(undefined);

  const handleCheckboxChange = (value: string | number) => {
    setSelectedAttendanceMode(value);
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    resolver: yupResolver(Schema().rsvpFormSchema),
    mode: "onBlur",
  });

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await registerRsvp({ ...data, attendParty, selectedAttendanceMode });
      toast.success("RSVP submitted successfully!");
      closeModal(); // Close modal on success
    } catch (error: any) {
      console.error("Failed to submit RSVP:", error);
      toast.error("Failed to submit RSVP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div
      style={{
        marginTop: "-80px",
      }}
    >
      <DonationHeadingWrapper>
        <HeaderTitle>Party RSVP</HeaderTitle>
        <Text>
          Your presence will bring life to the party—join me on{" "}
          <span
            style={{
              color: "#d1b34f",
            }}
          >
            Sunday, December 15, 2024
          </span>
        </Text>
      </DonationHeadingWrapper>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Container
          style={{
            margin: "0",
            padding: "0",
            minWidth: "100%",
          }}
        >
          <Row
            style={{
              marginBottom: "10px",
            }}
          >
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
              <InputComponent
                label={"First name"}
                placeholder={"Enter your first name"}
                hasLabel={false}
                name={"firstName"}
                errorText={errors?.firstName?.message}
                rest={{ ...register("firstName") }}
                id={"firstName"}
                type={"text"}
                cName={"form-input"}
                style={{
                  width: "100%",
                }}
                control={control}
                defaultValue={""}
              />
            </Col>

            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
              <InputComponent
                label={"Last name"}
                placeholder={"Enter your last name"}
                hasLabel={false}
                name={"lastName"}
                errorText={errors?.lastName?.message}
                rest={{ ...register("lastName") }}
                id={"lastName"}
                type={"text"}
                cName={"form-input"}
                style={{
                  width: "100%",
                }}
                control={control}
                defaultValue={""}
              />
            </Col>
          </Row>

          <Row
            style={{
              marginBottom: "5px",
            }}
          >
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
              <InputComponent
                label={"Phone Number"}
                placeholder={"Mobile Number"}
                hasLabel={false}
                name={"phoneNumber"}
                errorText={errors?.phoneNumber?.message}
                rest={{ ...register("phoneNumber") }}
                id={"phoneNumber"}
                type={"phone"}
                cName={"form-input"}
                control={control}
                defaultValue={""}
              />
            </Col>

            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 2 }}>
              <InputComponent
                label={"Email Address"}
                placeholder={"Email"}
                hasLabel={false}
                name={"email"}
                errorText={errors?.email?.message}
                rest={{ ...register("email") }}
                id={"email"}
                type={"email"}
                cName={"form-input"}
                style={{
                  width: "100%",
                }}
                control={control}
                defaultValue={""}
              />
            </Col>
          </Row>

          <Row
            style={{
              marginBottom: "5px",
            }}
          >
            <Col>
              <RadioComponent
                options={attendTheParty}
                hasLabel={true}
                label={"Will you be attending the party?"}
                errorText={errors?.attendParty?.message}
                name="attendParty"
                rest={{ ...register("attendParty") }}
                handleChange={(value) => {
                  setAttendParty(value);
                }}
                value={attendParty}
                selectedValue={attendParty}
                control={control}
                defaultValue={"yes"}
              />
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                marginTop: "5px",
              }}
            >
              <CheckboxComponent
                placeholder={""}
                label={"How will you attend the event?"}
                name={"mediumOfAttendance"}
                id={"mediumOfAttendance"}
                cName={"form-input p-0"}
                options={modeOfAttendance}
                hasLabel={true}
                control={control}
                rest={{ ...register("mediumOfAttendance") }}
                errorText={errors?.mediumOfAttendance?.message}
                handleChange={handleCheckboxChange}
                selectedValue={selectedAttendanceMode}
              />
            </Col>
          </Row>
          <Row
            style={{
              marginBottom: "5px",
            }}
          >
            <Col>
              <InputComponent
                label={""}
                placeholder={"How many people are you bringing with you?"}
                hasLabel={false}
                name={"numberOfPeople"}
                errorText={errors?.numberOfPeople?.message}
                rest={{ ...register("numberOfPeople") }}
                id={"numberOfPeople"}
                type={"number"}
                cName={"form-input"}
                control={control}
                defaultValue={""}
                style={{
                  width: "100%",
                  marginBottom: 0,
                }}
              />
            </Col>
          </Row>
          <BtnWrapper>
            <ButtonComponent
              isAnimatable={true}
              text={isLoading ? "Submitting..." : "Submit"}
              isLoading={isLoading}
              hasLine={true}
              buttonType={ButtonType.PRIMARY}
              cName=""
              style={{ width: "100%" }}
              click={handleSubmit(onSubmit)}
            />
          </BtnWrapper>
        </Container>
      </form>
    </div>
  );
};

export default RsvpForm;

const DonationHeadingWrapper = styled.div`
  padding: 0;
  margin: 0;
`;

const HeaderTitle = styled.h3`
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 37px;
  color: #d1b34f;
`;

const Text = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  padding: 0;
  margin: 0;
`;
const BtnWrapper = styled.div`
  margin-top: 10px;
`;
