import React from "react";

type Props = {
  children: React.ReactNode;
  href: string;
};

const AnchorTag = ({ children, href }: Props) => {
  return (
    <a
      href={href}
      rel="noreferrer noopener"
      hrefLang="en-us"
      target="_blank"
      style={{
        textDecoration: "none",
      }}
    >
      {children}
    </a>
  );
};

export default AnchorTag;
