import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useExternalMedia } from "../../hooks/useExternalMedia";
import AnchorTag from "../../utils/anchor-tag/AnchorTag";
import ButtonComponent from "../../utils/button/ButtonComponent";
import { ButtonType } from "../../types/general.types";
import { externalLinks } from "../../constants/constants";


const LogoHeader = () => {
  const { logo } = useExternalMedia();
  return (
    <Container>
      <Row>
        <Col>
          <LogoSection>
            <Img src={logo} alt="logo" style={{ cursor: "pointer" }} />

            <AnchorTag
              href={externalLinks.CALENDLY}
              children={
                <ButtonComponent
                  isAnimatable={true}
                  text="Book Consultation"
                  hasLine={true}
                  buttonType={ButtonType.PRIMARY}
                  cName=""
                  style={{ width: "196px", border: "none" }}
                  click={() => {}}
                  isLoading={false}
                />
              }
            />
          </LogoSection>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoHeader;

const LogoSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const Img = styled.img`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 64px;
  height: 64px;
`;
