export const Colors = () => {
  const whiteColor: string = "#fff", // white
    blackColor: string = "#111111",
    lightDarkColor: string = "#161616",
    goldColor: string = "#D1B34F",
    primaryColor: string = "#D1B34F",
    linkPrimaryColor: string = "#201F4F", // dark blue
    secondaryColor: string = "#EB8145", // orange
    tertiaryColor: string = "#fff", // white
    fourthColor: string = "#BABAC8", // light gray 🎨
    lightestOrange: string = "#fff0e8", //lightest orange
    dangerColor: string = "#F03738", // reddish
    warningColor: string = "#F3BB1C", // yellowish
    successColor: string = "#F03738", // greenish
    foundationColor: string = "#0D0D21", // dark blue
    darkBgColor: string = "#111",
    darkOrangeColor: string = "#EB8145",
    darkGrayColor: string = "#CFCFCF",
    lightGreenColor: string = "#89B369",
    lightBlackColor: string = "#414141",
    titleBlackColor: string = "#4f4f4f",
    offWhite: string = "red",
    lightBorderColor: string = "#B9B9B9";
  return {
    whiteColor,
offWhite,
    blackColor,
    lightDarkColor,
    goldColor,
    primaryColor,
    lightestOrange,
    dangerColor,
    foundationColor,
    warningColor,
    successColor,
    darkBgColor,
    darkOrangeColor,
    darkGrayColor,
    lightBlackColor,
    lightGreenColor,
    lightBorderColor,
    titleBlackColor,   secondaryColor,
    tertiaryColor,
    fourthColor,linkPrimaryColor
  };
};
