import React, { Fragment, useEffect, useState } from "react";
import RsvpSummary from "./component/RsvpSummary";
import ResponsiveTable from "./component/RsvpTable";
import { useModal } from "../../../../../hooks/utils.hooks";
import { SmallModal } from "../../../../../utils/modals/Modal";
import DeleteAttendee from "./component/DeleteAttendee";
import {
  deleteRsvpById,
  getAllRsvps,
} from "../../../../../services/rsvpServices/rsvpServices";

type Props = {};

const DashboardOverview = (props: Props) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [rsvps, setRsvps] = useState<any[]>([]);
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [selectedRsvpId, setSelectedRsvpId] = useState<number | string | null>(
    null
  );
  const [soloExtra, setSoloExtra] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  const { open, openModal, closeModal } = useModal();


  useEffect(() => {
    const fetchRsvps = async () => {
      try {
        const response = await getAllRsvps();
        const rsvpData = response?.data || [];
        setRsvps(rsvpData);
        setTotalDocs(response.totalDocs);
        const attendeesCount = rsvpData.reduce(
          (total: number, attendee: any) =>
            total + (attendee.numberOfPeople || 0) + 1,
          0
        );
        setTotalAttendees(attendeesCount);
        const soloExtraCount = rsvpData.reduce(
          (total: number, attendee: any) =>
            total + (attendee.numberOfPeople || 0),
          0
        );
        setSoloExtra(soloExtraCount);
      } catch (error) {
        console.error("Failed to fetch RSVPs", error);
      }
    };

    fetchRsvps();
  }, []);

  const handleDelete = async () => {

    if (!selectedRsvpId) return;

    try {
      setIsDeleting(true);
      await deleteRsvpById(selectedRsvpId);
      setRsvps((prev) => prev.filter((rsvp) => rsvp._id !== selectedRsvpId));
      setSelectedRsvpId(null);
      setIsDeleting(false);
      getAllRsvps(); // Refresh the list
      closeModal();
    } catch (error) {
      console.error("Error deleting RSVP:", error);
    }
  };

  const handleOpenModal = (id: number | string) => {
    setSelectedRsvpId(id);
    openModal();
  };
  return (
    <Fragment>
      <div
        style={{
          width: "100%",
          height: "auto",
        }}
      >
        <RsvpSummary
          totalAttendee={totalAttendees}
          solo={totalDocs}
          soloExtra={soloExtra}
        />
      </div>

      <div
        style={{
          width: "100%",
        }}
      >
        <ResponsiveTable data={rsvps} openModal={handleOpenModal} />
      </div>

      <SmallModal
        open={open}
        showCloseIcon={false}
        openModal={openModal}
        closeModal={closeModal}
        children={
          <DeleteAttendee
            closeModal={closeModal}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
            text={"Are you sure you want to delete this attendee?"}
          />
        }
      />
    </Fragment>
  );
};

export default DashboardOverview;
