import React from "react";
import styled from "styled-components";
import NonProfitData from "../../assets/data/non-profit/NonProfitData";
import HoveredCard from "../hovered-card/HoveredCard";

const NonProfitCards = () => {
  const nonProfit = NonProfitData();
  return (
    <HoveredCardWrapper>
      {nonProfit.map((nonProfiting) => (
        <HoveredCard
          key={nonProfiting.id}
          actionable={nonProfiting.actionable}
          id={nonProfiting.id}
          description={nonProfiting.description}
          title={nonProfiting.title}
          link={nonProfiting.link}
          style={{
            width: "326.5px",
            height: "350px",
          }}
        />
      ))}
    </HoveredCardWrapper>
  );
};

export default NonProfitCards;

const HoveredCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
