import { useExternalMedia } from "../../../hooks/useExternalMedia";

export const CurrentService = () => {
  const { talentAcquisition, appointment, gmb } = useExternalMedia();

  const serviceLinks = [
    {
      href: "https://www.hrtechish.com/resume",
      src: talentAcquisition,
      alt: "Talent Acquisition",
    },
    {
      href: "https://era-tek.io/eratek-1kb-initiative",
      src: gmb,
      alt: "GMB",
    },
    {
      href: "https://calendly.com/bare/eratalk?month=2024-05",
      src: appointment,
      alt: "Book Appointment",
    },
  ];

  return serviceLinks;
};

export const extendedServices = [
  {
    id: 0,
    title: "Apply for HR Related Jobs",
    description:
      "Ready to take your HR career to the next level? Explore top HR opportunities tailored to your skills and expertise. From HR tech roles to talent management, find your next big opportunity with ease.",
    actionable: "Apply",
    link: "https://www.hrtechish.com/job",
  },
  {
    id: 1,
    title: "Join Monthly HRIS Clinic",
    description:
      "Explore the latest in HR technology with our interactive Monthly HRIS Clinic. Connect with experts, get your questions answered, and gain actionable insights to enhance your HR tech skills. Don’t miss this chance to learn, grow, and stay ahead in the future of work!",
    actionable: "RSVP",
    link: "https://www.eventbrite.com/e/hris-clinic-an-hr-and-technology-qa-session-tickets-846040799807",
  },
  {
    id: 2,
    title: "ASk HR Related Questions",
    description:
      "Have burning questions about HR technology? Share them with us ahead of our Live Q&A sessions! Don’t miss this chance to get the answers you need—submit your questions today!",
    actionable: "Submit Question",
    link: "https://www.eventbrite.com/e/hris-clinic-an-hr-and-technology-qa-session-tickets-846040799807",
  },
];
