import { useEffect, useRef } from "react";
import AboutMe from "../../components/about-me";
import Communities from "../../components/communities/Communities";
import ExtendedServices from "../../components/extended-services";
import FundRaising from "../../components/fund-raising";
import GetInTouch from "../../components/get-in-touch";
import Hero from "../../components/hero";
import MyBrands from "../../components/my-brands";
import SupportNonProfit from "../../components/support-non-profit";
import WhatIDo from "../../components/what-i-does";


export default function HomePage() {
  const fundRaisingRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (fundRaisingRef.current !== null) {
      fundRaisingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <Hero />
      <div  ref={fundRaisingRef} id="fundRaising">

      <FundRaising />
      </div>
      <AboutMe />
      <WhatIDo />
      <ExtendedServices />
      <MyBrands />
      <SupportNonProfit />
      <Communities />
      <GetInTouch />
    </>
  );
}
