import React, { useState } from "react";
import styled from "styled-components";
import { useExport } from "../../../hooks/useExport";
import { useModal } from "../../../hooks/utils.hooks";
import { MediumModal } from "../../../utils/modals/Modal";
import {
  BankDetails,
  CashAppDetails,
} from "./BankDetails";
import { bankDetails } from "../../../constants/constants";

type Props = {
  closeModal: () => void;
};

export default function Donation({ closeModal }: Props) {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const { bankTransferIcon, paystackActiveIcon } = useExport();

  const {
    open: openCapitalOne,
    openModal: openCapitalOneModal,
    closeModal: closeCapitalOneModal,
  } = useModal();
  const {
    open: openZenithBank,
    openModal: openZenithBankModal,
    closeModal: closeZenithBankModal,
  } = useModal();
  const {
    open: openCashapp,
    openModal: openCashappModal,
    closeModal: closeCashappModal,
  } = useModal();

  const handleMouseEnter = (index: number) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  return (
    <div style={{ marginTop: "-80px" }}>
      <DonationHeadingWrapper>
        <HeaderTitle>Make Donation</HeaderTitle>
        <Text>
          Your donations supports our programs and efforts to empower young
          people in Africa
        </Text>
      </DonationHeadingWrapper>
      <MainIconsWrapper>
        {[
          {
            label: "Bank Transfer - US (Capital One)",
            onClick: openCapitalOneModal,
          },
          {
            label: "Bank Transfer - Nig (Zenith)",
            onClick: openZenithBankModal,
          },
          { label: "CashApp - US", onClick: openCashappModal },
        ].map((item, index) => (
          <IconWrapper
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={item.onClick}
            style={{
              backgroundColor: hoverIndex === index ? "#ffffff" : "#282828",
            }}
          >
            <img
              src={hoverIndex === index ? paystackActiveIcon : bankTransferIcon}
              alt={item.label}
            />
            <IconText
              style={{ color: hoverIndex === index ? "#111111" : "#fdfcfa" }}
            >
              {item.label}
            </IconText>
          </IconWrapper>
        ))}
      </MainIconsWrapper>

      <MediumModal
        open={openCapitalOne}
        closeModal={closeCapitalOneModal}
        children={
          <BankDetails
            closeModal={closeCapitalOneModal}
            bankAccountName={bankDetails.bankAccountName}
            bankName={bankDetails.capitalOneBank}
            hasRouting={true}
            hasSwiftCode={false}
            swiftCode=""
            routing={bankDetails.capitalOneRouting}
            accountNumber={bankDetails.capitalOneAccNumber}
          />
        }
      />
      <MediumModal
        open={openZenithBank}
        closeModal={closeZenithBankModal}
        children={ <BankDetails
          closeModal={closeZenithBankModal}
          bankAccountName={bankDetails.bankAccountName}
          bankName={bankDetails.zenithBank}
          hasRouting={false}
          hasSwiftCode={true}
          swiftCode={bankDetails.zenithBankSwiftCode}
          routing={""}
          accountNumber={bankDetails.zenithBankAccountNumber}
        />}
      />
      <MediumModal
        open={openCashapp}
        closeModal={closeCashappModal}
        children={<CashAppDetails closeModal={closeCashappModal} />}
      />
    </div>
  );
}

const DonationHeadingWrapper = styled.div`
  padding: 0;
  margin: 0;
`;

const HeaderTitle = styled.h3`
  font-family: "Gilroy-Bold";
  font-size: 30px;
  color: #d1b34f;
`;

const Text = styled.p`
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #ffffff;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
  gap: 10px;
  width: 120px;
  height: 160px;
  background: #282828;
  border-radius: 15px;
  @media (min-width: 568px) {
    width: 150px;
  }
`;

const MainIconsWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (min-width: 568px) {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const IconText = styled.p`
  font-family: "Gilroy-Medium";
  font-size: 12px;
  text-align: center;
  color: #fdfcfa;
`;
