import React, { Fragment } from "react";
import "./Radio.css";
import {RadioGroup, Radio} from "react-radio-group"
import { IRadioComponent } from "../../types/general.types";
import styled from "styled-components";
import DOMPurify from "dompurify";
export default function RadioComponent({
  selectedValue,
  handleChange,
  name,
  options,
  errorText,control,
  value,hasLabel,label,
  ...rest
}: IRadioComponent) {
  return (
    <>
      <RadioGroup
        name={name}
        selectedValue={selectedValue}
        value={value}
        control={control}
        onChange={handleChange}
      >
         {hasLabel && (<label className="form-label" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(label) }} />
        )}
        <RadioGroupWrapper>
          {options?.map((option :any ) => (
            <Fragment key={option.label}>
              <Label>
                <Radio
                  value={option.value}
                  {...rest}
                  className="custom-radio"
                  id="accent"
                  checked={selectedValue === option.value}
                />
                {option.label}
              </Label>
            </Fragment>
          ))}
        </RadioGroupWrapper>
      </RadioGroup>

      {errorText && errorText?.trim()?.length > 0 && (
        <p
          style={{
            color: "#F03738",
            margin: 0,
          }}
        >
          {errorText || ""}
        </p>
      )}
    </>
  );
}

const RadioGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  
`;
