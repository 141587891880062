import React from "react";
import {
  ColumnDef,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";
import styled from "styled-components";
//import "./RsvpTable.css"

type TableRow = {
  _id: string;
  firstName: string;
  lastName: string;
  number: string;
  email: string;
  attendParty: "Yes" | "No";
  numberOfPeople: number;
};

type Props = {
  data: TableRow[];
  openModal: (id: number|string) => void; 
};

const ResponsiveTable: React.FC<Props> = ({ data, openModal }: Props) => {
  //const { open, openModal, closeModal } = useModal();
  
  const columns: ColumnDef<TableRow>[] = [
    {
      accessorKey: "firstName",
      header: "First Name",
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
    },
    {
      accessorKey: "phoneNumber",
      header: "Number",
    },
    {
      accessorKey: "email",
      header: "Email",
    },
    
    {
      accessorKey: "selectedAttendanceMode",
      header: "Attendance Mode",
    },
    {
      accessorKey: "attendParty",
      header: "Attendance",
    },
    {
      accessorKey: "numberOfPeople",
      header: "Extra Att.",
    },
    {
      accessorKey: "_id",
      header: "Action",
      cell: ({ row }) => (
        <div style={{ display: "flex", gap: "12px" }}>
          <img
            src="https://res.cloudinary.com/bacf/image/upload/v1733262602/BASH-PERSONAL-WEBSITE/reviewAttendee-icon_uwql4c.svg"
            alt="review-attendee"
          />
          <img
            src="https://res.cloudinary.com/bacf/image/upload/v1733262602/BASH-PERSONAL-WEBSITE/deleteAttendee-icon_cze7nr.svg"
            alt="delete-attendee"
            onClick={() => openModal(row.original._id)}
            />
        </div>
      ),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div style={{ overflowX: "auto", marginTop: "40px" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledTh key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </StyledTh>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      padding: "10px 0",
                      color: "#111",
                      fontFamily: "Gilroy-SemiBold",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ResponsiveTable;

const StyledTh = styled.th`
  padding: 24px 0;
  background: #fdfbf6;
  border-radius: 20px 20px 0px 0px;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #d1b34f;
`;
