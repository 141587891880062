import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import TitleHeading from "../../utils/title-heading/TitleHeading";
import {
  socialLinks,
  quickLinks,
} from "../../assets/data/social-links/socialLinks";
import SocialLinks from "./SocialLinks";
import { useExport } from "../../hooks/useExport";
import { Colors } from "../../utils/colors/colors";
import QuickLinks from "./QuickLinks";
import AnchorTag from "../../utils/anchor-tag/AnchorTag";
import { externalLinks } from "../../constants/constants";

type Props = {};

const GetInTouch = (props: Props) => {
  const { primaryBoldArrow, whiteBoldArrow } = useExport();
  const [mouseEnter, setMouseEnter] = useState(false);
  const { whiteColor, goldColor } = Colors();
  return (
    <section id="get-in-touch">
      <Container
        style={{
          marginTop: "100px",
        }}
      >
        <Row>
          <AnchorTag
            href={externalLinks.CALENDLY}
            children={
              <GetInTouchWrapper
                data-aos-once="true"
                data-aos="fade-up"
                onMouseEnter={() => setMouseEnter(true)}
                onMouseLeave={() => setMouseEnter(false)}
                onClick={() => setMouseEnter(true)}
              >
                <GetInTouchText
                  data-aos-once="true"
                  data-aos="fade-up"
                  style={{
                    color: !mouseEnter ? whiteColor : goldColor,
                  }}
                >
                  SCHEDULE AN APPOINTMENT
                  <ImgWrapper>
                    <Img
                      data-aos-once="true"
                      data-aos="fade-up"
                      src={!mouseEnter ? whiteBoldArrow : primaryBoldArrow}
                      alt="arrow-up"
                    />
                  </ImgWrapper>
                </GetInTouchText>
              </GetInTouchWrapper>
            }
          ></AnchorTag>
        </Row>

        <Row
          style={{
            marginTop: "60px",
          }}
        >
          <SocialsWrapper>
            <SocialMainWrapper>
              <TitleHeading title="Socials" />
              <SocialLinksWrapper>
                {socialLinks.map((socialLink) => (
                  <SocialLinks {...socialLink} key={socialLink.id} />
                ))}
              </SocialLinksWrapper>
            </SocialMainWrapper>
            <SocialMainWrapper>
              <TitleHeading title="Quick Links" />
              <SocialLinksWrapper>
                {quickLinks.map((quickLink) => (
                  <QuickLinks {...quickLink} key={quickLink.id} />
                ))}
              </SocialLinksWrapper>
            </SocialMainWrapper>
          </SocialsWrapper>
        </Row>
      </Container>
    </section>
  );
};

export default GetInTouch;

const GetInTouchText = styled.div`
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 2rem;
  line-height: 89px;
  display: flex;
  gap: 20px;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
  @media (min-width: 568px) {
    font-size: 3rem;
  }
  @media (min-width: 991px) {
    font-size: 72px;
  }
`;

const GetInTouchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 100%;
`;

const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;

  width: 100%;
  max-width: 100%;

  @media (min-width: 773px) {
    flex-direction: row;
  }
`;

const SocialMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  margin-top: 60px;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  gap: 20px;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Img = styled.img``;
