import primaryLine from "../assets/icons/Line-primary.svg";
import whiteLine from "../assets/icons/Line-white.svg";
import modalCloseIcon from "../assets/icons/modal-close-icon.svg";
import paystackIcon from "../assets/icons/paystack-icon.svg";
import paystackActiveIcon from "../assets/icons/paystack-active.svg";
import bankTransferIcon from "../assets/icons/bank-transfer-icon.svg";
import copyBold from "../assets/icons/copy-bold.svg";
import infoIcon from "../assets/icons/info-icon.svg";
import eraconnecktLogo from "../assets/icons/eraconneckt-logo.svg";
import hrtechishLogo from "../assets/icons/hrtechish-logo.svg";
import primaryBoldArrow from "../assets/icons/primary-bold-arrow.svg";
import whiteBoldArrow from "../assets/icons/white-bold-arrow.svg";
import darkInstagram from "../assets/icons/dark-insta.svg";
import darkTwitter from "../assets/icons/dark-twitter.svg";
import darkLinkedln from "../assets/icons/dark-linkedln.svg";
import lightInstagram from "../assets/icons/light-insta.svg";
import lightTwitter from "../assets/icons/light-twitter.svg";
import lightLinkedln from "../assets/icons/light-linkedln.svg";
import greenArrow from "../assets/icons/green-arrow.svg";
import lightArrow from "../assets/icons/light-arrow.svg";
import footerX from "../assets/icons/footerX.svg";
import footerFacebook from "../assets/icons/footerFacebook.svg";
import footerInsta from "../assets/icons/footerInsta.svg";
import footerLinkedln from "../assets/icons/footerLinkedln.svg";
import barCode from "../assets/icons/bar-code.svg";
import dashboardRsvp from "../assets/icons/rsvp-dashboard-icon.svg";
import deleteAttendeeIcon from "../assets/icons/deleteAttendee-icon.svg";
import reviewAttendeeIcon from "../assets/icons/reviewAttendee-icon.svg";
import deleteIcon from "../assets/icons/delete-icon.svg";
import cashAppQrCode from "../assets/icons/cashapp-qrcode.svg";
const merchImage =
  "https://res.cloudinary.com/bacf/image/upload/v1732833634/BASH-PERSONAL-WEBSITE/here_sjdmlv.svg";
export const useExport = () => {
  return {
    primaryLine,
    whiteLine,
    modalCloseIcon,
    paystackIcon,
    bankTransferIcon,
    copyBold,
    paystackActiveIcon,
    infoIcon,
    eraconnecktLogo,
    hrtechishLogo,
    primaryBoldArrow,
    whiteBoldArrow,
    merchImage,
    darkInstagram,
    darkTwitter,
    darkLinkedln,
    lightInstagram,
    lightTwitter,
    lightLinkedln,
    greenArrow,
    lightArrow,
    footerX,
    footerFacebook,
    footerInsta,
    footerLinkedln,
    barCode,
    dashboardRsvp,
    deleteAttendeeIcon,
    reviewAttendeeIcon,deleteIcon, cashAppQrCode
  };
};
