import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageViewer from "./PageViewer";
import "./DashboardLayout.css";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useExternalMedia } from "../../../../hooks/useExternalMedia";
import { HOME } from "../../../../routes/path.routes";
import DashboardSidebar from "./Sidebar";

export default function DashboardLayout() {
  const [showSideBar, setShowSidebar] = useState<boolean>(false);

  const { logo } = useExternalMedia();

  return (
    <>
      <>
        <main className="section layout-section" id="layout">
          <Container
            style={{
              maxWidth: "100%",
            }}
          >
            <Row
            >
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 1, order: 1 }}
                className="show-on-large-screen"
              >
                <Link to={HOME}>
                  <LogoWrapper>
                    <Img src={logo} alt="bash Logo" />
                  </LogoWrapper>
                </Link>
                <DashboardSidebar />
              </Col>

              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                lg={{ span: 11, order: 2 }} 
              >
                <PageViewer setShowSidebar={setShowSidebar} />
              </Col>
            </Row>
          </Container>
        </main>
      </>
    </>
  );
}

const LogoWrapper = styled.div`
  justify-content: center;
  align-items: center;
  height: var(--dashboard-navbar-height);
  display: none !important;
  background-color: #111111;
  margin: 60px 0;
  max-width: 150px;

  @media (min-width: 1024px) {
    display: flex !important;
  }
`;

const Img = styled.img`
  align-items: center;
`;
