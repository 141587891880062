import React from 'react'
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"

type Props = {}

const BashBrief = (props: Props) => {
  return (
    <Container>

<Row>
            <Col>
              <BioWrapper data-aos="fade-up" data-aos-once="true">
                <Bio data-aos="fade-up" data-aos-once="true">
                  I'm Bashirat Are, an HRIS and Project Management Consultant. I
                  help companies streamline their HR processes, optimize
                  workforce operations, and drive digital transformation through
                  innovative technology solutions.
                </Bio>
              </BioWrapper>
            </Col>
          </Row>


    </Container>
  )
}

export default BashBrief;


const BioWrapper = styled.div`
  margin-top: 150px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 700px) {
    margin-top: 220px;
  }
`;
const Bio = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  display: flex;
  align-items: justify;
  color: var(--white-color);
  opacity: 0.7;




  @media (min-width: 568px) {
    font-size: 24px;
  }
`;
