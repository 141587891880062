






















//? environments
export const ENV = `${process.env.REACT_APP_ENVIRONMENT}`;
export const DEV_ENV = "development";
export const PROD_ENV = "production";





export const constants = () => {

  return {
    API_URL: ENV === DEV_ENV?.toLowerCase() ? `${process.env.REACT_APP_DEV_URL}/v1/api` : `${process.env.REACT_APP_API_URL}/v1/api`, //? ==> dev env || prod env
    MICROSOFT_CLARITY_ID: `${process.env.REACT_APP_MICROSOFT_CLARITY_ID}`,
  }
};

export const externalLinks = {
  CALENDLY:"https://calendly.com/bare/eratalk?"
}

export const bankDetails = {
  capitalOneAccNumber : "36292644299",
  capitalOneRouting:"#031176110",
  zenithBankAccountNumber:"4234806508",
  zenithBankSwiftCode:"ZEIBNGLAXXX",
  bankAccountName:"Bashirat Olubunmi Are",
  capitalOneBank: "Capital One Bank", zenithBank:"Zenith Bank NG"
}
export const roles = {
  ADMIN: "Admin",
  SUPER_ADMIN: "Super Admin",
  RECRUITER: "Recruiter"
}

export const userAccountStates = {
  ACTIVE: "active",
  INACTIVE: "inactive"
};


export const a = "a"

export const FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes
export const SUPPORTED_FORMATS = ["application/pdf", "image/jpeg", "image/png"];