import React from "react";
import { Helmet } from "react-helmet";
const HelmetHandler = () => {
  return (
    <Helmet>
      <meta
        property="og:title"
        content="Bash’s Fundraiser | Supporting Eraconneckt"
      />
      <meta
        property="og:description"
        content="Celebrate Bash's birthday and support a greater cause! Join us for a special fundraiser event for Eraconneckt, a NGO dedicated to positioning African youths for global opportunities. Your RSVP helps us empower the next generation. Learn more and get involved!"
      />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/bacf/image/upload/v1733529024/BASH-PERSONAL-WEBSITE/1733528825674_vyrzgh.jpg"
      />
    </Helmet>
  );
};

export default HelmetHandler;
