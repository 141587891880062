const bashHeaderImage =
  "https://res.cloudinary.com/bacf/image/upload/v1710107632/BASH-PERSONAL-WEBSITE/Picture_of_bashirat_are_standing_boss_lady_cylnr7.png";
const logo =
  "https://res.cloudinary.com/bacf/image/upload/v1710200816/BASH-PERSONAL-WEBSITE/Union_eaphkv.png";
const line =
  "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Line_3_allznb.png";
const cardIcon =
  "https://res.cloudinary.com/bacf/image/upload/v1710622858/BASH-PERSONAL-WEBSITE/Group_5634_fpigam.png";
const rightLine =
  "https://res.cloudinary.com/bacf/image/upload/v1710703035/BASH-PERSONAL-WEBSITE/Line_5_vgn1rn.png";
const leftLine =
  "https://res.cloudinary.com/bacf/image/upload/v1710703035/BASH-PERSONAL-WEBSITE/Line_4_jpoxx6.png";
const bacfLogo =
  "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_50_ozxld8.svg";
const eraconnecktLogo =
  "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_50_1_agyvnv.svg";
const eratekLogo =
  "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Frame_50_2_jidrxm.svg";
const hrtechLogo =
  "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Frame_50_3_lri0nw.svg";
const bashImage =
  "https://res.cloudinary.com/bacf/image/upload/v1710107618/BASH-PERSONAL-WEBSITE/Frame_46_ylbgtm.png";
const arrowUp =
  "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Line_6_c1ycib.svg";
const talentAcquisition =
  "https://res.cloudinary.com/bacf/image/upload/v1720889396/BASH-PERSONAL-WEBSITE/taf_imhyyj.png";
const appointment =
  "https://res.cloudinary.com/bacf/image/upload/v1720889396/BASH-PERSONAL-WEBSITE/appoint_hjijwc.png";
const gmb =
  "https://res.cloudinary.com/bacf/image/upload/v1720889396/BASH-PERSONAL-WEBSITE/gmb_jc4kxj.jpg";
const bashImageForm =
  "https://res.cloudinary.com/bacf/image/upload/v1720899247/BASH-PERSONAL-WEBSITE/bash-resize_fmrrku.png";
const lightModeBash =
  "https://res.cloudinary.com/bacf/image/upload/v1700178588/Rectangle_11_1_krc3gw.png";
const darkModeBash =
  "https://res.cloudinary.com/bacf/image/upload/v1700178588/Rectangle_11_eruqet.png";
const reviewAttendee =
  "https://res.cloudinary.com/bacf/image/upload/v1733262602/BASH-PERSONAL-WEBSITE/reviewAttendee-icon_uwql4c.svg";
const deleteAttendee =
  "https://res.cloudinary.com/bacf/image/upload/v1733262602/BASH-PERSONAL-WEBSITE/deleteAttendee-icon_cze7nr.svg";

export const useExternalMedia = () => {
  return {
    bashHeaderImage,
    logo,
    line,
    cardIcon,
    rightLine,
    leftLine,
    bacfLogo,
    eraconnecktLogo,
    eratekLogo,
    hrtechLogo,
    bashImage,
    arrowUp,
    talentAcquisition,
    appointment,
    gmb,
    bashImageForm,
    lightModeBash,
    darkModeBash, deleteAttendee, reviewAttendee
  };
};
