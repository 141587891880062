

const NonProfitData = () => {

  const nonProfit = [
    {
      id: 0,
      title: "https://res.cloudinary.com/bacf/image/upload/v1732050421/BASH-PERSONAL-WEBSITE/eraconneckt-logo_q8gcvc.svg",
      description:
        "Join us in transforming lives across Africa with Eraconneckt. Your support—through donations, partnerships, or volunteering—helps us create sustainable change and unlock the continent's potential. Support Eraconneckt today!",
      actionable: "Check Us Out",link:"https://eraconneckt.org/"
    },
    // {
    //   id: 1,
    //   title: "https://res.cloudinary.com/bacf/image/upload/v1732050422/BASH-PERSONAL-WEBSITE/hrtechish-logo_mxt1mo.svg",
    //   description:
    //     "Help us drive innovation and growth in HR technology! By supporting HRTechish, you’re contributing to a community that inspires HR professionals, fosters learning, and advances the future of work. Join us in shaping the future.",
    //   actionable: "Check Us Out", link:"https://www.hrtechish.com/"
    // },
  ];
  return nonProfit;
};

export default NonProfitData;
