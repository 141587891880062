import React, { Fragment, useState } from "react";
import styled from "styled-components";
//import P from '../../../../../utils/p/P';
//import { Colors } from '../../../../../utils/colors/colors';
//import { useIcons } from '../../../../../hooks/useIcons';
//import { dashboardSideNavData } from "./../../../../../assets/data/dashboard-sidenav-data/dashboardSidenavData";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useLocationPathName } from "../../../../hooks/useLocationPathname";
import { Colors } from "../../../../utils/colors/colors";
import { useExport } from "../../../../hooks/useExport";
import { dashboardSideNavData } from "../../../../assets/data/dashboard-sidenav-data/dashboardSideNavData";
//import { useLocationPathName } from '../../../../../hooks/useLocationPathName';

export default function DashboardSidebar() {
  const [showSubNavListItems, setShowSubNavListItems] = useState<string>("");

  const location = useLocation();
  const useLocationPathNameProps = {
    location,
    limit: 2,
    isFirstLetterUppercased: false,
    searchQuery: "type",
  };

  const { path, searchParam } = useLocationPathName(useLocationPathNameProps);

  const {  darkGrayColor, blackColor, primaryColor } = Colors();

  const { greenArrow, lightArrow } = useExport();

  return (
    <>
      <SidebarWrapper>
        <SidebarContainer>
          {dashboardSideNavData?.map((sideNavData) => (
            <Fragment key={sideNavData.id}>
              <Link
                to={sideNavData.path}
                onClick={() => setShowSubNavListItems(sideNavData.path)}
              >
                <NavWrapper
                  style={{
                    borderBottom: `${
                      sideNavData.hasSubNavListItems &&
                      showSubNavListItems === sideNavData.path
                        ? "1px"
                        : "0"
                    } solid ${darkGrayColor}`,
                    backgroundColor:
                      showSubNavListItems === sideNavData.path
                        ? "rgba(254, 247, 251, 0.08)"
                        : blackColor,
                    borderLeft:
                      path === sideNavData.path
                        ? `2px solid ${primaryColor}`
                        : "none",
                  }}
                >
                  <NavIconWrapper>
                    <Icon src={sideNavData.icon} alt="icon" />
                  </NavIconWrapper>
                </NavWrapper>
              </Link>
            </Fragment>
          ))}
        </SidebarContainer>
      </SidebarWrapper>
    </>
  );
}

const SidebarWrapper = styled.div`
  min-height: calc(100vh - var(--dashboard-navbar-height));
  max-height: calc(100vh - var(--dashboard-navbar-height));
  background-color: #111111;
  color: var(--tertiary-color);
  height: 100%;
  overflow: auto;

  width: 120px;
  @media (min-width: 768px) {
    max-width: var(--dashboard-sidebar-width) !important;
  }
`;

const SidebarContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  //border-bottom: 1px solid #b7b7b7;
  padding: 4rem 0;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  padding: 1rem 1rem 1rem 1.5rem;
  cursor: pointer;
`;

const NavIconWrapper = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;
