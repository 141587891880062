import { useTheme } from "../../../context/ThemeContext";
import { useExport } from "../../../hooks/useExport";

export const IconData = () => {
  const { isDarkMode } = useTheme();
  const {
    darkInstagram,
    darkTwitter,
    darkLinkedln,
    lightInstagram,
    lightTwitter,
    lightLinkedln,
  } = useExport();

  const icons = [
    {
      id: 0,
      imgSrc: isDarkMode ? darkLinkedln : lightLinkedln,
      link: "https://www.linkedin.com/in/bashiratare/",
      alt: "linkedln icon",
    },

    {
      id: 1,
      imgSrc: isDarkMode ? darkTwitter : lightTwitter,
      link: "https://x.com/TarishabEra?t=MZuDSk7p7h46PiZT5xgKKA&s=09",
      alt: "Twitter icon",
    },
    {
      id: 2,
      imgSrc: isDarkMode ? darkInstagram : lightInstagram,
      link: "https://www.instagram.com/tarishabera/",
      alt: "Instagram icon",
    },
  ];

  return icons;
};
