import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

type Props = {};

const BashBio = (props: Props) => {
  return (
    <Container>
      <Row>
        <Col>
          <TitleWrapper>
            <H1 data-aos-once="true" data-aos="fade-up">
              BASHIRAT ARE
            </H1>

            <MainSkillWrapper data-aos-once="true" data-aos="fade-up">
              <Skills>HRIS/HCM CONSULTANT</Skills>
              <Skills>PROJECT MANAGER</Skills>
              <Skills>OPERATIONS MANAGER</Skills>
              <Skills>DATA ANALYST</Skills>
            </MainSkillWrapper>
          </TitleWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default BashBio;

const H1 = styled.h1`
  font-size: 12.05vw;
  font-weight: 700;
  text-align: justify;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--gold-color);
  padding: 0 !important;
  margin: 0 !important;

  display: flex;
  align-items: start;
  letter-spacing: 0.03em;

  @media (min-width: 700px) {
    text-align: center;
  }
`;

const Skills = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  align-items: stretch;
  margin-bottom: 2rem;
  white-space: nowrap;
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  cursor: pointer;
`;

const MainSkillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  padding: 0;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  display:none;


@media (min-width: 700px) {
  display: flex;
  }

`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  margin-top: 50px;

  @media (min-width: 1000px) {
    margin-top: 20px;
  }
`;
