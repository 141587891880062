import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaSun, FaMoon } from "react-icons/fa";
import { Colors } from "../../utils/colors/colors";
import { useTheme } from "../../context/ThemeContext";
import Header from "./component/header/Header";
import BioLinkCards from "./component/bio-links/BioLinks";
import LinkTreeFooter from "./component/footer";
type Props = {};

const LinkTree = (props: Props) => {
  const { lightGreenColor, darkBgColor, whiteColor } = Colors();

  const { isDarkMode, toggleTheme } = useTheme();
  useEffect(() => {
    AOS.init({
      delay: 100,
    });
    AOS.refresh();
  }, []);
  return (
    <section
      className="section"
      style={{
        backgroundColor: isDarkMode ? darkBgColor : whiteColor,
      }}
    >
      {isDarkMode ? (
        <FaSun className="toggle-icon" onClick={toggleTheme} />
      ) : (
        <FaMoon
          className="toggle-icon"
          onClick={toggleTheme}
          style={{
            color: lightGreenColor,
          }}
        />
      )}
      <Container
        style={{
          backgroundColor: isDarkMode ? darkBgColor : whiteColor,
        }}
      >
        <Header />
        <BioLinkCards />
      </Container>
      <LinkTreeFooter />
    </section>
  );
};

export default LinkTree;
