import React from "react";
import styled from "styled-components";
import { useExport } from "../../../../../../hooks/useExport";
import ButtonComponent from "../../../../../../utils/button/ButtonComponent";
import { ButtonType } from "../../../../../../types/general.types";

type Props = {
  closeModal: () => void;
  handleDelete: () => Promise<void>;
  isDeleting: boolean;
  text: string;
};

const DeleteAttendee = ({
  closeModal,

  handleDelete,
  isDeleting,
  text,
}: Props) => {
  const { deleteIcon } = useExport();

  return (
    <DeleteAttendeeWrapper>
      <img src={deleteIcon} alt="delete-icon" />
      <Text>{text}</Text>

      <BtnWrapper>
        <ButtonComponent
          isAnimatable={true}
          text={isDeleting ? "deleting" : "Yes"}
          hasLine={true}
          buttonType={ButtonType.NEUTRAL}
          cName=""
          style={{
            width: "130px",
            border: "1px solid #d1b34f",
          }}
          click={handleDelete}
          isLoading={isDeleting}
        />

        <ButtonComponent
          isAnimatable={true}
          text="No"
          hasLine={true}
          buttonType={ButtonType.PRIMARY}
          cName=""
          style={{
            width: "130px",
            border: "none",
            outline: "none",
          }}
          click={closeModal}
          isLoading={false}
        />
      </BtnWrapper>
    </DeleteAttendeeWrapper>
  );
};

export default DeleteAttendee;

const DeleteAttendeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 28px 10px;
  gap: 20px;
  width: 350px;
  height: 226px;
  background: #111111;
  border-radius: 30px;
`;

const Text = styled.p`
  max-width: 219px;
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
