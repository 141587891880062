import React from "react";
import { Container } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

import styled from "styled-components";
import DashboardOverview from "../component/dashborad-overview/DashboardOverview";
import { RSVP } from "../../../../routes/path.routes";

type IProps = {
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
};

const PageViewer = ({ setShowSidebar }: IProps) => {
  return (
    <>
    
    <ContainerWrapper
        onClick={() => {
          setShowSidebar(false);
        }}
      >
        <Routes>
          <Route path={RSVP} element={<DashboardOverview />}></Route>
        </Routes>
      </ContainerWrapper>
    </>
  );
};

export default React.memo(PageViewer);

const PageViewerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 20px;
  gap: 30px;
  height: 100%;

  border:3px solid blue;
  min-height: 100%;
    min-width: 70%;
 // min-width: calc(100vw - var(--dashboard-sidebar-width));
  //max-height: calc(100vh - var(--dashboard-navbar-height));
  max-height: 100vh;

  background-color: #ffffff;
  overflow: auto;
  margin: 15px 0;
  z-index: -2;
  border-radius: 20px;

  @media (min-width: 1024px) {
    width: calc(100vw - var(--dashboard-sidebar-width));
  }
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 100% !important;
  padding: 60px 10px;
  gap: 30px;
  background: #ffffff;
  border-radius: 20px;
  margin: 10px 0;
  overflow:auto;
    min-height: 100%;  max-height: 100vh;



`;
