import { toast } from "react-toastify";
import {  getAllRsvps } from "../../../../../../services/rsvpServices/rsvpServices";



export const handleExportToCSV = async () => {
    try {
      const { data } = await getAllRsvps();
  
      // Convert the data to CSV format
      const csvData = [
        ['_id', 'First Name', 'Last Name', 'Phone Number', 'Email', 'Attend Party', 'Number of People', 'Created At', 'Updated At'],
        ...data.map((item: {
          _id: string;
          firstName: string;
          lastName: string;
          phoneNumber: string;
          email: string;
          attendParty: string;
          numberOfPeople: number;
          createdAt: string;
          updatedAt: string;
        }) => [
          item._id,
          item.firstName,
          item.lastName,
          item.phoneNumber,
          item.email,
          item.attendParty,
          item.numberOfPeople,
          item.createdAt,
          item.updatedAt
        ])
      ].map(row => row.join(',')).join('\n');
  
      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData));
      downloadLink.setAttribute('download', 'rsvp_data.csv');
  
      // Append the link to the document and click it
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
  
      toast.success("RSVP exported to CSV successfully!");
    } catch (error: any) {
      toast.error("Failed to export RSVP to CSV.");
      throw error;
    }
  };



  
