import styled from "styled-components";
import { Container, Row } from "react-bootstrap";
import { useExport } from "../../../../hooks/useExport";
import { useTheme } from "../../../../context/ThemeContext";
import { Colors } from "../../../../utils/colors/colors";

export default function LinkTreeFooter() {
  const { footerX, footerFacebook, footerInsta, footerLinkedln } = useExport();
  const { isDarkMode } = useTheme();
  const { darkBgColor, lightGreenColor } = Colors();

  const footerImgs = [
    {
      id: 0,
      src: footerX,
      alt: "footer x icon",
      link: "https://x.com/TarishabEra?t=MZuDSk7p7h46PiZT5xgKKA&s=09",
    },
    {
      id: 1,
      src: footerFacebook,
      alt: "footer Facebook icon",
      link: "https://web.facebook.com/renaissancehueman/?_rdc=1&_rdr",
    },
    {
      id: 2,
      src: footerInsta,
      alt: "footer Insta icon",
      link: "https://www.instagram.com/tarishabera/",
    },
    {
      id: 3,
      src: footerLinkedln,
      alt: "footer Linkedln icon",
      link: "https://www.linkedin.com/in/bashiratare/",
    },
  ];

  return (
    <section
      style={{
        backgroundColor: isDarkMode ? darkBgColor : lightGreenColor,
        padding: "10px 0",
      }}
    >
      <Container>
        <Row>
          <FooterWrapper>
            <P data-aos-once="true" data-aos="fade-up">
              Copyright © {new Date().getFullYear()} Bashirat Are | Designed by
              BACFInc
            </P>
            <FooterImgs>
              {footerImgs.map((footerImgs) => (
                <a
                  href={footerImgs.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  hrefLang="en-us"
                  key={footerImgs.id}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Img
                    src={footerImgs.src}
                    alt={footerImgs.alt}
                    className="icon"
                  />
                </a>
              ))}
            </FooterImgs>
          </FooterWrapper>
        </Row>
      </Container>
    </section>
  );
}

const P = styled.p`
  color: var(--white-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 212px;

  @media (min-width: 768px) {
    font-size: 20px;
    max-width: 550px;
  }
`;

const Img = styled.img`
  height: 25px;
  width: 25px;

  @media (min-width: 568px) {
    height: 30px;
    width: 30px;
  }
`;

const FooterImgs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 110px;
  @media (min-width: 768px) {
    max-width: 220px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
