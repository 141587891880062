import React from "react";
import "./index.css";
import LogoHeader from "./LogoHeader";
import BashBio from "./BashBio";
import BashBrief from "./BashBrief";

import { Element } from "react-scroll";
import MerchComponent from "../merch";

const NewHero: React.FC = () => {
  return (
    <div className="bg-image" >
      <Element className="content" name="home">
        <>
          <LogoHeader />

          <MerchComponent />
          <BashBio />
          <BashBrief />
        </>
      </Element>
    </div>
  );
};

export default NewHero;
