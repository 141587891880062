import { constants } from "../../constants/constants";
import axios from "axios";
import { toast } from "react-toastify";

const { API_URL } = constants();
const rsvpApi = `${API_URL}/rsvp`;

export const registerRsvp = async (data: any) => {
  try {
    const response = await axios.post(rsvpApi, data);
    toast.success("RSVP created successfully!");
    return response.data;
  } catch (error: any) {
    toast.error(error.response?.data?.error || "Failed to create RSVP.");
    throw error;
  }
};

export const getAllRsvps = async () => {
  try {
    const response = await axios.get(rsvpApi);
    return response.data;
  } catch (error: any) {
    toast.error("Failed to fetch RSVPs.");
    throw error;
  }
};

export const getRsvpById = async (id: string) => {
  try {
    const response = await axios.get(`${rsvpApi}/${id}`);
    return response.data;
  } catch (error: any) {
    toast.error("Failed to fetch RSVP details.");
    throw error;
  }
};

export const deleteRsvpById = async (id: number | string) => {
  try {
    await axios.delete(`${rsvpApi}/${id}`);
    toast.success("RSVP deleted successfully!");
  } catch (error: any) {
    toast.error("Failed to delete RSVP.");
    throw error;
  }
};

export const exportRsvpToCsv = async () => {
  try {
    await axios.post(`${rsvpApi}/export-rsvps`);
    toast.success("RSVP exported to CSV successfully!");
  } catch (error: any) {
    toast.error("Failed to export RSVP to CSV.");
    throw error;
  }
};

export const deleteAllRsvps = async () => {
  try {
    await axios.delete(rsvpApi);
    toast.success("All RSVPs deleted successfully!");
  } catch (error: any) {
    toast.error("Failed to delete RSVPs.");
    throw error;
  }
};

export const rsvpServices = {
  registerRsvp,
  getAllRsvps,
  getRsvpById,
  deleteAllRsvps,
  deleteRsvpById,
  exportRsvpToCsv,
};
