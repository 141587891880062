import React from "react";
import { Container, Row } from "react-bootstrap";
import TitleHeading from "../../utils/title-heading/TitleHeading";
import styled from "styled-components";
import { myBrands } from "../../assets/data/my-brands/myBrands";
import AnchorTag from "../../utils/anchor-tag/AnchorTag";

const MyBrands = () => {
  return (
    <Container
      style={{
        marginTop: "100px",
      }}
    >
      <Row>
        <TitleHeading title={"My Brands"} />
      </Row>
      <Row>
        <BrandLogoWrapper data-aos-once="true" data-aos="fade-up">
          {myBrands.map((brands) => (
            <AnchorTag
              href={brands.href}
              children={
                <BrandLogo data-aos-once="true" data-aos="fade-up">
                  <img src={brands.imgSrc} alt={brands.name} />
                </BrandLogo>
              }
              data-aos-once="true"
              data-aos="fade-up"
              key={brands.id}
            />
          ))}
        </BrandLogoWrapper>
      </Row>
    </Container>
  );
};

export default MyBrands;

const BrandLogo = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 8px;
  width: 280px;
  height: 140px;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 7px 7px 0px rgba(255, 255, 255, 0.1);
`;

const BrandLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  margin-top: 80px;

  @media (min-width: 600px) {
    justify-content: space-between;
  }
`;
