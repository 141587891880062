import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useExport } from "../../hooks/useExport";
import AnchorTag from "../../utils/anchor-tag/AnchorTag";
import { brandLinks } from "../../constants/brand.links";

type Props = {};

const MerchComponent = (props: Props) => {
  const { primaryLine, merchImage } = useExport();
  const { HRTECHISH } = brandLinks();
  return (
    <SectionWrapper>
      <Container>
        <Row>
          <Col
            sm={12}
            md={6}
            style={{
              backgroundColor: "#282828",
            }}
          >
            <LeftSide data-aos-once="true" data-aos="fade-up">
              <GearUpText>Gear Up in</GearUpText>
              <GearUpText
                style={{
                  color: "#D1B34F",
                }}
              >
                Style!
              </GearUpText>
            </LeftSide>
          </Col>

          <Col
            sm={12}
            md={6}
            style={{
              backgroundColor: "#161616",
            }}
          >
            <RightSideWrapper>
              <RightSide data-aos-once="true" data-aos="fade-up">
                <Text>
                  Be the first to know when our exclusive merch drops. Show your
                  interest today!
                </Text>
                <AnchorTag
                  href={HRTECHISH}
                  children={
                    <IconWrapper>
                      <SignUpText>Sign Up Now</SignUpText>
                      <img src={primaryLine} alt="arrow" />
                    </IconWrapper>
                  }
                />
              </RightSide>
            </RightSideWrapper>
          </Col>
        </Row>
      </Container>

      <ImageWrapper>
        <img src={merchImage} alt="Merchandise" />
      </ImageWrapper>
    </SectionWrapper>
  );
};

export default MerchComponent;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 10px;
  height: 300px;
  background: #282828;
`;
const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 300px;
  background-color: #161616;
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  gap: 20px;
`;

const GearUpText = styled.p`
  max-width: 358px;
  font-family: "Gilroy-BoldItalic";
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #fdfcfa;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 70px;
  }
`;

const Text = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.7;
  max-width: 400px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`;
const SignUpText = styled.p`
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #d1b34f;
  opacity: 0.8;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  display: none;
  transform: translate(-50%, -50%);
  z-index: 10;
  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (min-width: 1300px) {
    display: inline-block;
  }
`;

const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: -1;
  }

  &::before {
    left: 0;
    background-color: #282828;
  }

  &::after {
    right: 0;
    background-color: #161616;
  }
`;
