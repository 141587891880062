import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import NonProfitCards from "./NonProfitCards";
import { MediumModal } from "../../utils/modals/Modal";
import Donation from "../fund-raising/modal-components/Donation";
import { useModal } from "../../hooks/utils.hooks";

type Props = {};

const SupportNonProfit = (props: Props) => {
  const { open, openModal, closeModal } = useModal();
  return (
    <section>
      <Container
        style={{
          marginTop: "50px",
        }}
      >
        <Row>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: 1 }}
            lg={{ span: 6, order: 1 }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: "40px",
              marginBottom: "60px",
            }}
          >
            <div>
              <HeaderTitle data-aos-once="true" data-aos="fade-up">
                Support My Non-Profit
              </HeaderTitle>
              <HeaderDescription data-aos-once="true" data-aos="fade-up">
                Make a lasting impact! Your support helps create opportunities,
                empower communities, and drive meaningful change. Join us in
                building a brighter future—donate, partner, or volunteer today!
              </HeaderDescription>
            </div>

            <BtnWrapper onClick={openModal}>Support</BtnWrapper>
          </Col>

          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: 2 }}
            lg={{ span: 6, order: 2 }}
          >
            <NonProfitCards />
          </Col>
        </Row>
      </Container>

      <MediumModal
        open={open}
        showCloseIcon={false}
        openModal={openModal}
        closeModal={closeModal}
        children={<Donation closeModal={closeModal} />}
      />
    </section>
  );
};

export default SupportNonProfit;

const HeaderTitle = styled.h2`
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;
  color: #d1b34f;
  opacity: 0.8;
  @media (min-width: 568px) {
    font-size: 3rem;
  }
`;
const HeaderDescription = styled.p`
  font-family: "Gilroy-Medium";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;
  opacity: 0.5;

  @media (min-width: 568px) {
    font-size: 21px;
  }
`;

const BtnWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
  width: 120px;
  height: 49px;
  border: 1px solid #d1b34f;
  border-radius: 2px;

  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #d1b34f;
`;
