import React, { useState } from "react";
import styled from "styled-components";
import ButtonComponent from "../../../../../../utils/button/ButtonComponent";
import { ButtonType } from "../../../../../../types/general.types";
import { SmallModal } from "../../../../../../utils/modals/Modal";
import DeleteAttendee from "./DeleteAttendee";
import { useModal } from "../../../../../../hooks/utils.hooks";
import {
  deleteAllRsvps,
  getAllRsvps,
} from "../../../../../../services/rsvpServices/rsvpServices";
import {  handleExportToCSV } from "./RsvpSummaryService";

type Props = {
  totalAttendee: number;
  solo: number;
  soloExtra: number;
};

const RsvpSummary = ({ totalAttendee, solo, soloExtra }: Props) => {
  const { open, openModal, closeModal } = useModal();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deleteAllRsvps();
      setIsDeleting(false);
      getAllRsvps(); // Refresh the list
      closeModal();
    } catch (error) {
      console.error("Error deleting RSVP:", error);
    }
  };


  return (
    <>
      <RsvpSummaryWrapper>
        <RsvpSummaryMainWrapper>
          <TotalAttendeeWrapper>
            <SummaryTerm>Total Attendee</SummaryTerm>
            <SummaryValue>{totalAttendee}</SummaryValue>
          </TotalAttendeeWrapper>

          <SoloExtraWrapper>
            <SummaryTerm>Solo</SummaryTerm>
            <SummaryValue>{solo}</SummaryValue>
          </SoloExtraWrapper>
          <SoloExtraWrapper>
            <SummaryTerm>Solo + Extra</SummaryTerm>
            <SummaryValue>{soloExtra}</SummaryValue>
          </SoloExtraWrapper>
        </RsvpSummaryMainWrapper>

        <BtnWrapper>
          <ButtonComponent
            isAnimatable={true}
            text={isDeleting ? "...deleting all rsvps" : "Delete All"}
            hasLine={true}
            buttonType={ButtonType.TERTIARY}
            cName=""
            style={{
              width: "130px",
              border: "1px solid #d1b34f",
            }}
            click={openModal}
            isLoading={false}
          />

          <ButtonComponent
            isAnimatable={true}
            text="Export All"
            hasLine={true}
            buttonType={ButtonType.PRIMARY}
            cName=""
            style={{
              width: "130px",
              border: "none",
              outline: "none",
            }}
            click={handleExportToCSV}
            isLoading={false}
          />
        </BtnWrapper>
      </RsvpSummaryWrapper>

      <SmallModal
        open={open}
        showCloseIcon={false}
        openModal={openModal}
        closeModal={closeModal}
        children={
          <DeleteAttendee
            closeModal={closeModal}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
            text={
              "Are you sure you want to delete the rsvp attendees for this event?"
            }
          />
        }
      />
    </>
  );
};

export default RsvpSummary;

const RsvpSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  background-color: #ffffff;
  height: 100%;

  @media (min-width: 568px) {
    flex-direction: row;
  }
`;

const RsvpSummaryMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 60px;
  @media (min-width: 568px) {
    flex-direction: row;
    margin-bottom: 0;
  }
`;
const TotalAttendeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  gap: 10px;
  width: 192px;
  height: 147px;
  background: #282828;
  border-radius: 30px;
  cursor: pointer;
`;

const SummaryTerm = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #d1b34f;
  padding: 0;
  margin: 0;
`;
const SummaryValue = styled.p`
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 140%;
  color: #d1b34f;
  padding: 0;
  margin: 0;
`;

const SoloExtraWrapper = styled.div`/
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content:space-between;
padding: 30px;
gap: 10px;
width: 192px;
height: 147px;
background: #FDFBF6;
border-radius: 30px;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100% !important;
  @media (min-width: 568px) {
    align-items: flex-end;
    justify-content: flex-end;
    gap: 40px;
  }
`;
