import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useExport } from "../../hooks/useExport";
import { MediumModal } from "../../utils/modals/Modal";
import { useModal } from "../../hooks/utils.hooks";
import Donation from "./modal-components/Donation";
import RsvpForm from "./modal-components/RsvpForm";
import ButtonComponent from "../../utils/button/ButtonComponent";
import { ButtonType } from "../../types/general.types";

const FundRaising = () => {
  const { primaryLine } = useExport();

  const { open, openModal, closeModal } = useModal();

  const {
    open: openRsvp,
    openModal: openRsvpModal,
    closeModal: closeRsvpModal,
  } = useModal();
  return (
    <section>
      <Container style={{ marginTop: "80px" }}>
        <Row>
          <Col>
            <MainWrapper>
              <Wrapper>
                <Heading data-aos-once="true" data-aos="fade-up">
                  Celebrate with Me & Make a Difference
                </Heading>
                <Text data-aos-once="true" data-aos="fade-up">
                  Join me for a special birthday celebration while supporting
                  Eraconneckt's mission to empower African youth. Your presence
                  and contributions will help us continue our impactful work.
                </Text>
                <ActionMainWrapper data-aos-once="true" data-aos="fade-up">
                
                  <ActionBtnWrapper
                    onClick={openRsvpModal}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <Rsvp
                      style={{
                        color: "#D1B34F",
                        opacity: 0.8,
                      }}
                    >
                      RSVP
                    </Rsvp>
                    <Icon src={primaryLine} alt="line" />
                  </ActionBtnWrapper>
                  <ButtonComponent
                    isAnimatable={true}
                    text={"DONATE"}
                    hasLine={true}
                    buttonType={ButtonType.PRIMARY}
                    cName=""
                    style={{
                      width: "116px",
                      border: "none",
                    }}
                    click={openModal}
                    isLoading={false}
                  />
                </ActionMainWrapper>
              </Wrapper>
            </MainWrapper>
          </Col>
        </Row>
      </Container>

      <MediumModal
        open={open}
        showCloseIcon={false}
        openModal={openModal}
        closeModal={closeModal}
        children={<Donation closeModal={closeModal} />}
      />
      <MediumModal
        open={openRsvp}
        showCloseIcon={false}
        openModal={openRsvpModal}
        closeModal={closeRsvpModal}
        children={<RsvpForm closeModal={closeRsvpModal} />}
      />
    </section>
  );
};

export default FundRaising;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 60px;
  gap: 70px;
  background: #282828;

  @media (min-width: 600px) {
    gap: 50px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
`;
const Heading = styled.h1`
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 120%;
  text-align: center;
  color: #d1b34f;
  opacity: 0.8;
  max-width: 958px;
  padding: 0;
  margin: 0;

  @media (min-width: 468px) {
    font-size: 3rem;
  }
`;
const Text = styled.p`
  font-family: "Gilroy-MediumItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  max-width: 710px;
  padding: 0;
  margin: 0;

  @media (min-width: 468px) {
    font-size: 21px;
  }
`;

const Rsvp = styled.p`
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 21px;
  line-height: 160%;
  color: #d1b34f;
  opacity: 0.8;
`;

const ActionBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 10px;
  width: 116px;
  height: 49px;
  background: #d1b34f;
  border-radius: 2px;
  cursor: pointer;
`;
const ActionMainWrapper = styled.div`
  display: flex;
  gap: 20px;
  padding: 0;
  margin: 0;
`;
const Icon = styled.img``;
