type ISocialLinks = {
  link: string;
  linkName: string;
  id: number;
}[];

export const socialLinks: ISocialLinks = [
  {
    linkName: "TWITTER",
    link: "https://x.com/TarishabEra?t=MZuDSk7p7h46PiZT5xgKKA&s=09",
    id: 0,
  },
  {
    linkName: "LINKEDIN",
    link: "https://www.linkedin.com/in/bashiratare/",
    id: 1,
  },
  {
    linkName: "FACEBOOK",
    link: "https://web.facebook.com/renaissancehueman/?_rdc=1&_rdr",
    id: 2,
  },
  {
    linkName: "INSTAGRAM",
    link: "https://www.instagram.com/tarishabera/",
    id: 3,
  }, {
    linkName: "EMAIL",
    link: "mailto:bashirata@bacfinc.com",
    id: 3,
  },
];

export const quickLinks: ISocialLinks = [
    {
      linkName: "Home",
      link: "home",
      id: 0,
    },
    {
      linkName: "About Me",
      link: "aboutMe",
      id: 1,
    },
    {
      linkName: "My Journey",
      link: "myJourney",
      id: 2,
    },
  ];
