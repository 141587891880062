import React, { useState } from "react";
import styled from "styled-components";
import { Colors } from "../../utils/colors/colors";
import { useExport } from "../../hooks/useExport";

type Props = {
  actionable: string;
  description: string;
  id: number;
  title: string | React.ReactNode;
  style?: any;
  link?: string;
};

const HoveredCard = ({
  actionable,
  description,
  id,
  title,
  style,
  link,
}: Props) => {
  const { blackColor, lightDarkColor, whiteColor, goldColor } = Colors();
  const [mouseEnter, setMouseEnter] = useState(false);
  const { primaryLine, whiteLine } = useExport();

  return (
    <div>
      <HoveredCardWrapper
        data-aos-once="true"
        data-aos="fade-up"
        key={id}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        onClick={() => setMouseEnter(true)}
        style={{
          border: !mouseEnter
            ? "1px solid rgba(255, 255, 255, 0.5)"
            : " 1px solid #e3d195",
          backgroundColor: !mouseEnter ? lightDarkColor : blackColor,
          boxShadow: !mouseEnter
            ? "none"
            : "8px 8px 0px rgba(209, 179, 79, 0.2)",
          ...style,
        }}
      >
        <HoveredCardMainWrapper data-aos-once="true" data-aos="fade-up">
          {typeof title === "string" && title.startsWith("http") ? (
            <div
              style={{
                height: "50px",
              }}
            >
              <img src={title} alt="icon" />
            </div>
          ) : (
            <Text data-aos-once="true" data-aos="fade-up">
              {title}
            </Text>
          )}

          <DescriptionText data-aos-once="true" data-aos="fade-up">
            {description}
          </DescriptionText>
        </HoveredCardMainWrapper>

        <IconWrapper
          href={link ? link : ""}
          target={link ? "_blank" : ""}
          rel={link ? "noopener noreferrer" : ""}
          hrefLang="en-us"
          data-aos-once="true"
          data-aos="fade-up"
        >
          <RsvpText
            style={{
              color: mouseEnter ? goldColor : whiteColor,
            }}
          >
            {actionable}
          </RsvpText>
          <img src={!mouseEnter ? whiteLine : primaryLine} alt="icon" />
        </IconWrapper>
      </HoveredCardWrapper>
    </div>
  );
};

export default HoveredCard;

const HoveredCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  gap: 50px;
  width: 326.5px;
  height: 350px;

  border: 1px solid rgba(255, 255, 255, 0.5);

  @media (min-width: 468px) {
    width: 466.67px;
  }
`;

const HoveredCardMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
`;
const Text = styled.p`
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
`;

const RsvpText = styled.p`
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #ffffff;
  opacity: 0.8;
`;

const DescriptionText = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #cfcfcf;
`;

const IconWrapper = styled.a`
  display: flex;
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
`;
