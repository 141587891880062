import * as yup from "yup";

export const Schema = () => {
  const rsvpFormSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim("Cannot be empty space")
      .required("First name is required."),
    lastName: yup
      .string()
      .trim("Cannot be empty space")
      .required("Last name is required."),

    phoneNumber: yup
      .string()
      .trim("Cannot be empty space")
      .required("Phone number is required."),
    email: yup
      .string()
      .trim("Cannot be empty space")
      .email("Must be a valid email")
      .required("Email is required."),
    attendParty: yup.mixed().notRequired().nullable(),
    numberOfPeople: yup
      .string()
      .trim("Cannot be empty space")
      .required("Phone number is required."),    mediumOfAttendance: yup.mixed().required("Please select an option."),

  });

  const paystackFormSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim("Cannot be empty space")
      .required("First name is required."),
    lastName: yup
      .string()
      .trim("Cannot be empty space")
      .required("Last name is required."),

    phoneNumber: yup
      .string()
      .trim("Cannot be empty space")
      .required("Phone number is required."),
    email: yup
      .string()
      .trim("Cannot be empty space")
      .email("Must be a valid email")
      .required("Email is required."),
    amount: yup
      .string()
      .trim("Cannot be empty space")
      .required("Amount is required"),
  });

  return {
    rsvpFormSchema,paystackFormSchema
  };
};
