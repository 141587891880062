
export const HOME = "/";
export const INDEX = "/";
export const GMB = "google-my-business";
export const TALENT_ACQUISITION = "talent-acquisition";
export const BOOK_APPOINTMENT = "book-appointment";
export const SUPPORT_MY_NGO = "support-ngo";
export const LINK_TREE ="link-tree";
export const DASHBOARD = "dashboard"
export const RSVP = "rsvp";
export const SERVICES = "services"
export const DASHBOARD_OVERVIEW = "rsvp";
export const WILD_CARD = "*"
