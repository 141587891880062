import React, { Fragment } from "react";
import Marquee from "react-fast-marquee";
import { extendedServices } from "../../assets/data/services/serviceLinks";
import ExtendedServicesCard from "./ExtendedServices";
type Props = {};

const ExtendedServices = (props: Props) => {
  return (
    <section id="extended-services" className="extended-services" style={{
        marginTop:"50px"
    }}>
      <Marquee speed={50} pauseOnHover={true} loop={false}>
        {extendedServices?.map((extendedService) => {
          return (
            <Fragment key={extendedService.id}>
              <ExtendedServicesCard {...extendedService} />
            </Fragment>
          );
        })}
      </Marquee>
    </section>
  );
};

export default ExtendedServices;
