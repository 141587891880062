export const myBrands = [
  {
    id: 0,
    href: "https://bacfinc.com/",
    imgSrc:
      "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_50_ozxld8.svg",
    name: "bacf",
  },
  {
    id: 1,
    href: "https://eraconneckt.com/",
    imgSrc:
      "https://res.cloudinary.com/bacf/image/upload/v1710107612/BASH-PERSONAL-WEBSITE/Frame_50_1_agyvnv.svg",
    name: "eraconneckt",
  },
  {
    id: 2,
    href: "https://www.era-tek.io/",
    imgSrc:
      "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Frame_50_2_jidrxm.svg",name:"eratek"
  },
  {
    id: 3,
    href: "https://hrtechish.com/",
    imgSrc:
      "https://res.cloudinary.com/bacf/image/upload/v1710107610/BASH-PERSONAL-WEBSITE/Frame_50_3_lri0nw.svg",name:"hrtechish"
  },
];
