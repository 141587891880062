import React, { useState } from "react";
import styled from "styled-components";
import { useExport } from "../../../hooks/useExport";
import { IBankDetails } from "../../../types/general.types";

type Props = {
  closeModal: () => void;
};

export const BankDetails = ({
  closeModal,
  bankAccountName,
  bankName,
  routing,
  hasRouting,
  swiftCode,
  hasSwiftCode,
  accountNumber,
}: IBankDetails) => {
  const [copiedAcc, setCopiedAcc] = useState(false);
  const [copiedSwift, setCopiedSwift] = useState(false);

  const { copyBold } = useExport();

  const copyAccountNumber = "0751329897";
  const copySwiftCode = "GTBIGB2L";
  const code = hasSwiftCode ? swiftCode : routing
  const handleAccountCopy = () => {
    navigator.clipboard
      .writeText(accountNumber)
      .then(() => {
        setCopiedAcc(true);
        setTimeout(() => setCopiedAcc(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSwiftCodeCopy = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopiedSwift(true);
        setTimeout(() => setCopiedSwift(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <DetailsWrapper>
      <DetailsTextWrapper>
        <DetailsText>Bank Name:</DetailsText>
        <DetailsMainText>{bankName}</DetailsMainText>
      </DetailsTextWrapper>

      <AccountDetailsWrapper>
        <DetailsTextWrapper>
          <DetailsText>Bank Account Name:</DetailsText>
          <DetailsMainText>{bankAccountName}</DetailsMainText>
        </DetailsTextWrapper>
        <DetailsTextWrapper>
          <DetailsText>Account Number:</DetailsText>
          <WrapText>
            <DetailsMainText>{accountNumber}</DetailsMainText>{" "}
            <img src={copyBold} alt="copy-icon" onClick={handleAccountCopy} />
            {copiedAcc && (
              <span
                style={{
                  color: "#d1b34f",
                }}
              >
                copied!
              </span>
            )}
          </WrapText>
        </DetailsTextWrapper>
      </AccountDetailsWrapper>

      {hasSwiftCode && (
        <DetailsTextWrapper>
          <DetailsText>International Routing (SWIFT-BIC) Code:</DetailsText>
          <WrapText>
            <DetailsMainText>{swiftCode}</DetailsMainText>{" "}
            <img src={copyBold} alt="copy-icon" onClick={handleSwiftCodeCopy} />
            {copiedSwift && (
              <span
                style={{
                  color: "#d1b34f",
                }}
              >
                copied!
              </span>
            )}
          </WrapText>
        </DetailsTextWrapper>
      )}

      {hasRouting && (
        <DetailsTextWrapper>
          <DetailsText>Capital Bank Routing</DetailsText>
          <WrapText>
            <DetailsMainText>{routing}</DetailsMainText>
            <img src={copyBold} alt="copy-icon" onClick={handleSwiftCodeCopy} />
            {copiedSwift && (
              <span
                style={{
                  color: "#d1b34f",
                }}
              >
                copied!
              </span>
            )}
          </WrapText>
        </DetailsTextWrapper>
      )}
    </DetailsWrapper>
  );
};


export const CashAppDetails = ({ closeModal }: Props) => {

  const { cashAppQrCode } = useExport();

  return (
    <CashAppWrapper>
      <CashAppMain>
        <BarCodeBackground>
          <img src={cashAppQrCode} alt="alt-icon" style={{
            height:"64px", width:"64px"
          }} />
        </BarCodeBackground>

        <DetailsTextWrapper>
          <DetailsText
            style={{
              margin: "2px",
              padding: "2px",
            }}
          >
            Cashapp Name
          </DetailsText>
          <DetailsMainText
            style={{
              margin: "2px",
              padding: "2px",
            }}
          >
            Bashirat$
          </DetailsMainText>
        </DetailsTextWrapper>
      </CashAppMain>
    </CashAppWrapper>
  );
};

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 30px;
  gap: 10px;
  width: 450px;
  height: auto;
  background: transparent;
  border-radius: 15px;
`;

const DetailsText = styled.p`
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  padding: 0;
  margin: 0;
`;

const DetailsMainText = styled.p`
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 0;
  margin: 0;
`;
const DetailsTextWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

const BankDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WrapText = styled.div`
  display: flex;
  gap: 8px;
`;
const AccountDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const CashAppWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  gap: 10px;
  max-width: 500px;
  height: 160px;
  background: transparent;
  border-radius: 15px;
`;

const CashAppMain = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const BarCodeBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1b34f;
  width: 80px;
  height: 80px;
`;
