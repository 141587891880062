import React from "react";
import styled from "styled-components";

type Props = {};

const AboutMeDetails = (props: Props) => {
  return (
    <AboutMeWrapper data-aos-once="true" data-aos="fade-up">
      <MySaying data-aos-once="true" data-aos="fade-up">
        “I am passionate about shaping businesses through technology and
        enabling people to unleash their full potential.”
      </MySaying>

      <MySummary data-aos-once="true" data-aos="fade-up">
        As an accomplished HRIS/HCM consultant and project manager with a deep
        understanding of the challenges faced by modern organizations, I craft
        tailored solutions that harmonize cutting-edge technologies with
        business objectives, enabling companies to streamline operations,
        enhance workforce engagement, and gain a competitive edge. <br /> <br />
        I actively engage with industry leaders, stay abreast of emerging
        trends, and leverage best practices to deliver innovative and
        sustainable solutions. Whether it's optimizing HR processes, executing
        complex projects, or driving operational efficiencies, I approach each
        challenge with a solutions-oriented mindset and a collaborative spirit.
      </MySummary>
    </AboutMeWrapper>
  );
};
const AboutMeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;
  margin-bottom: 2rem;

  gap: 60px;
`;
export default AboutMeDetails;

const MySaying = styled.p`
  font-family: "Gilroy-MediumItalic";

  font-style: italic;
  font-weight: 400;
  font-size: 1rem;
  line-height: 160%;
  color: var(--gold-color);
  opacity: 0.8;
  padding-right: 5px;

  @media (min-width: 568px) {
    font-size: 24px;
  }
`;

const MySummary = styled.p`
font-weight: 400;
font-size: 14px;
line-height: 160%;

font-style: italic;
font-family: 'Gilroy-MediumItalic';
padding-right:5px
color: var(--white-color);
opacity: 0.5;


@media (min-width: 568px) {
font-size: 21px;
}

`;
