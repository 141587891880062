
import { RSVP } from "../../../routes/path.routes";
import dashboardRsvp from "../../icons/rsvp-dashboard-icon.svg";





export const dashboardSideNavData= [
    {
      id: 1,
      name: "Overview",
      hasSubNavListItems: false,
      icon: dashboardRsvp,
      path: RSVP,      subNavListItems: [
        {
          id: 1,
          name: "Bridal",
          path: "make_up",
        },
        {
          id: 2,
          name: "Photoshoot",
          path: "photoshoot",
        },
        {
          id: 3,
          name: "Glam Session",
          path: "g_session",
        },
        {
          id: 4,
          name: "Make Up Classes",
          path: "session",
        },
      ],
    },
  ];