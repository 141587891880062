import React, { Fragment } from 'react';
import Checkbox from 'react-simple-checkbox';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import './CheckboxComponent.css';
import { Colors } from '../colors/colors';

interface Option {
  label: string;
  value: string | number;
}

interface CheckboxComponentProps {
  placeholder?: string;
  name: string;
  id?: string;
  onChange?: (value: any) => void;
  control: any; // Should match the type provided by react-hook-form
  options: Option[];
  style?: React.CSSProperties;
  value?: any;
  hasLabel?: boolean;
  label?: string;
  selectedValue?: any;
  errorText?: string;
  cName?: string;
  handleChange: (value: any) => void;
  [key: string]: any; // Allow additional props
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
  placeholder,
  name,
  id,
  onChange,
  control,
  options,
  style,
  value,
  hasLabel,
  label,
  selectedValue,
  errorText,
  cName,
  handleChange,
  ...rest
}) => {
  const { dangerColor } = Colors();

  return (
    <CheckBoxGroupWrapper>
      <div className="d-flex flex-column">
        {hasLabel && <label className="form-label">{label}</label>}

        <Controller
          name={name}
          control={control}
          defaultValue={value}
          render={({ field: { onChange: fieldOnChange, onBlur, ref } }) => (
            <CheckBoxWrapper>
              {options?.map((option) => (
                <Fragment key={option.label}>
                  <Label>
                    <Checkbox
                      name={name}
                      placeholder={placeholder}
                      id={id}
                      checked={selectedValue?.includes(option.value)}
                      onChange={() => {
                        fieldOnChange(option.value); // Update react-hook-form state
                        handleChange(option.value); // Call the parent handler
                      }}
                      style={{
                        ...style,
                        borderColor: errorText && dangerColor,
                      }}
                      defaultValue={value}
                      onBlur={onBlur}
                      ref={ref}
                      value={option.value}
                      color={{
                        backgroundColor: '#d1b34f',
                        uncheckedBorderColor: '#d1b34f',
                        borderColor: '#d1b34f',
                        tickColor: '#FFFFFF',
                      }}
                      size={1}
                      borderThickness={3}
                      tickSize={3}
                      tickAnimationDuration={100}
                      {...rest}
                    />
                    {option.label}
                  </Label>
                </Fragment>
              ))}
            </CheckBoxWrapper>
          )}
        />
      </div>
      {errorText && (
        <small
          style={{
            margin: 0,
            color: dangerColor,
          }}
        >
          {errorText || ''}
        </small>
      )}
    </CheckBoxGroupWrapper>
  );
};

const CheckBoxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  flex-wrap: wrap;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: fit-content;
  cursor: pointer;
`;

export default CheckboxComponent;
