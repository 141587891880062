
import Communities from "../../components/communities/Communities";
import FundRaising from "../../components/fund-raising";
import GetInTouch from "../../components/get-in-touch";
import MyBrands from "../../components/my-brands";
import SupportNonProfit from "../../components/support-non-profit";
import LogoHeader from "../../components/hero/LogoHeader";
type Props = {}

const SupportMyNgo = (props: Props) => {
  return (
    <>
    <LogoHeader />

    <FundRaising />
    <MyBrands />
    <SupportNonProfit />
    <Communities />
    <GetInTouch />
  </>
  )
}

export default SupportMyNgo