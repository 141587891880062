import React from "react";
import ThemeProvider from "../../context/ThemeContext";
import LinkTree from "../../components/link-tree";

type Props = {};

const LinkTreePage = (props: Props) => {
  return (
    <>
      <ThemeProvider>
        <LinkTree />
      </ThemeProvider>
    </>
  );
};

export default LinkTreePage;
