
export const attendTheParty = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  export const modeOfAttendance = [

    {
      label: "Virtually (Online)",
      value: "Virtual"
    },  
    {
      label: "Physically (In-Person)",
      value: "Physical"
    },
    
  
  ]
  