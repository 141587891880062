import styled from "styled-components";
import { IconData } from "../../../../assets/data/link-tree/IconData";

const Icons = () => {
  const icons = IconData();
  return (
    <IconWrapper 
    data-aos-once="true"
    data-aos="fade-up">
      {icons.map((icon) => (
        <a href={icon.link} target="_blank" hrefLang="en-us" rel="noreferrer">
          <img
            src={icon.imgSrc}
            alt={icon.alt}
            className="icon"
            key={icon.id}
          />
        </a>
      ))}
    </IconWrapper>
  );
};

export default Icons;

const IconWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
