import Button from "react-bootstrap/Button";
import { Colors } from "../colors/colors";
import "./ButtonComponent.css";
import { IButton } from "../../types/general.types";
import Windmill from "react-activity/dist/Windmill";

const ButtonComponent = ({
  text,
  hasLine,
  style,
  click,
  isLoading,
  buttonType,
  cName,
  isAnimatable,
}: IButton) => {
  const { primaryColor, whiteColor, blackColor } = Colors();

  return (
    <>
      <Button
        data-aos={isAnimatable ? `fade-up` : ``}
        data-aos-once="true"
        className={`button ${
          buttonType === "PRIMARY"
            ? "btn-primary primary-button"
            : buttonType === "SECONDARY"
            ? "btn-secondary secondary-button"
            : buttonType === "TERTIARY"
            ? "btn-tertiary tertiary-button"
            : buttonType === "NEUTRAL"?"btn-neutral neutral-button":""
        } ${cName && cName}`}
        bsPrefix="btn"
        onClick={click}
        style={{
          ...style,
          backgroundColor:
            buttonType === "PRIMARY"
              ? primaryColor
              : buttonType === "SECONDARY"
              ? whiteColor
              : buttonType === "TERTIARY"
              ? whiteColor
              : buttonType === "NEUTRAL"? blackColor:primaryColor,
          color:
            buttonType === "PRIMARY"
              ? blackColor
              : buttonType === "SECONDARY"
              ? primaryColor
              : buttonType === "TERTIARY"
              ? primaryColor:buttonType === "NEUTRAL"? primaryColor
              : whiteColor,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 12px",
          gap: "10px",
          height: "56px",
          flex: "none",
          order: 2,
          flexGrow: 0,
          cursor: isLoading ? "not-allowed" : "pointer",
          fontFamily: "Gilroy-Medium",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "17px", transition: "color 0.3s ease, background-color 0.3s ease"
         
        }}
        disabled={isLoading}
      >
        {isLoading && (
          <Windmill
            color={
              buttonType === "PRIMARY"
                ? whiteColor
                : buttonType === "SECONDARY"
                ? primaryColor
                : buttonType === "TERTIARY"
                ? blackColor
                : primaryColor
            }
          />
        )}{" "}
        {text}
      </Button>
    </>
  );
};

export default ButtonComponent;
