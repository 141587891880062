
import { HorizontalRule } from "./HorizontalRule";
import BioCards from "./BioCards";
import { useTheme } from "../../../../context/ThemeContext";
import { Colors } from "../../../../utils/colors/colors";

export default function BioLinkCards() {
  const { isDarkMode } = useTheme();
  const { darkOrangeColor, lightGreenColor } = Colors();
  return (
    <>
      <HorizontalRule
        text="LINKS"
        textColor={isDarkMode ? darkOrangeColor : lightGreenColor}
        style={{}}
      />
      <BioCards />
    </>
  );
}
