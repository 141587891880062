import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import {
  HOME,
  INDEX,
  SUPPORT_MY_NGO,
  LINK_TREE,
  DASHBOARD_OVERVIEW,
  DASHBOARD,
  WILD_CARD,
} from "./routes/path.routes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import HomePage from "./pages/Homepage";
import SupportMyNgo from "./pages/support-ngo";
import LinkTreePage from "./pages/link-tree";
import DashboardLayout from "./components/admin/dashboard/layout/DashboardLayout";
import HelmetHandler from "./utils/seo/HelmetHandler";

function App() {
  useEffect(() => {
    AOS.init({
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <HelmetHandler />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <Routes>
          <Route>
            <Route element={<HomePage />} path={HOME} />
            <Route element={<HomePage />} path={INDEX} />

            <Route element={<SupportMyNgo />} path={SUPPORT_MY_NGO} />
            <Route element={<LinkTreePage />} path={LINK_TREE} />

            <Route
              path={`/${DASHBOARD}/${WILD_CARD}`}
              element={<DashboardLayout />}
            />
            <Route
              path={DASHBOARD}
              element={<Navigate to={DASHBOARD_OVERVIEW} replace />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
