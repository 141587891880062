import React from "react";
import HoveredCard from "../hovered-card/HoveredCard";
import { IHoveredCard } from "../../types/general.types";

const ExtendedServicesCard = ({
  actionable,
  description,
  id,
  title,
  link,
}: IHoveredCard) => {
  return (
    <HoveredCard
      actionable={actionable}
      title={title}
      id={id}
      description={description}
      link={link}
      style={{
        margin: "1rem",
        height: "370px",
        padding: "20px",
      }}
    />
  );
};

export default ExtendedServicesCard;
