import React from "react";
import styled from "styled-components";
import { ModalIcon } from "../icon/Icon";


type Props = {
    onClose: () => void;
    children: React.ReactNode;
  
}

export default function ModalWrapper({ onClose, children }:Props) {
 
  return (
    <>
      <ModalIconWrapper>
        <ModalIcon closeModal={onClose} />
      </ModalIconWrapper>
      {children}
    </>
  );
}

const ModalIconWrapper = styled.div`

  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  display: flex;
  align-self: flex-end;
  justify-content: end;
  align-self: end;
  z-index: 9999;
`;
